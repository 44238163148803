import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sorokin_prolongs_till_2023" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Владислав Сорокин, 24-летний игрок сборной Латвии, продлевает свой контракт с ФК РФС еще на два года.</p>
          <p>Сорокин провел 121 матч за ФК РФС и является рекордсменом клуба.</p>
          <p>Спортивное агентство Catenaccio желает Владиславу пойти еще дальше и как можно скорее пересечь отметку в 150 матчей! 💪💪💪</p>
        </div>
      </div>
    )
  }
}